import React from "react"
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  video: {
    position: "relative",
    width: "100%",
    paddingBottom: "56.25%", /* 16:9 */
    height: 0
  },
  frame: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  }
})

const Video = ({ videoSrcURL, videoTitle, ...props }) => {
  const classes = useStyles()
  return (
  <div className={classes.video}>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      className={classes.frame}
    />
  </div>
)}
export default Video