import React from "react"
import { graphql } from "gatsby"
// import { Button } from "@material-ui/core"
import Layout from "@components/layout"
import SEO from "@components/seo"
import GridContainer from '@components/Grid/GridContainer'
import GridItem from '@components/Grid/GridItem'
import clsx from 'clsx'
import styles from '@assets/jss/views/components.js'
import { makeStyles } from '@material-ui/core/styles'
import Parallax from '@components/Parallax/Parallax'
import ImageGallery from "../components/ImageGallery/ImageGallery"
import Video from "@components/video"

const useStyles = makeStyles(styles)

const Gallery  = (props) => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  const classImages = data.classFile.edges.map(item => {
    return item.node.childImageSharp.fluid
  })
  const activityImages = data.activityFile.edges.map(item => {
    return item.node.childImageSharp.fluid
  })
  const classes = useStyles()
  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="小舞花 照片 视频 photo video" />
      <Parallax
        filter
        image={require("@assets/img/landing-bg.jpg")}
        style={{ height: "180px" }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={clsx(classes.main, classes.mainRaised)}  style={{ marginTop: "-80px" }}>
        <div className={classes.container}>
          <h2 className={classes.subtitle2}>课堂照片</h2>
          <ImageGallery images={classImages}></ImageGallery>
          <br /><br /><br />
        </div>
        <div className={classes.container}>
          <h2 className={classes.subtitle2}>演出花絮</h2>
          <ImageGallery images={activityImages}></ImageGallery>
          <br /><br /><br />
        </div>
        <div className={classes.container}>
          <h2 className={classes.subtitle2}>精彩视频</h2>
          <Video videoSrcURL="https://www.youtube.com/embed/khmyafo8418?rel=0&iv_load_policy=3&disablekb=1&showinfo=0" siteTitle="扇子舞《十面埋伏》"></Video>
          <Video videoSrcURL="https://www.youtube-nocookie.com/embed/Ngn-hvOMAGw?list=PL_9rJWdsvY9ccABSKYovqhi6DMjlRYmHY&rel=0&iv_load_policy=3&disablekb=1&showinfo=0" siteTitle="扇子舞《十面埋伏》"></Video>
          <br /><br /><br /> 
        </div>
      </div>
    </Layout>
  )
}

export default Gallery

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    classFile: allFile(filter: {sourceInstanceName: {eq: "class"}}) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
            fluid {
              aspectRatio
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    activityFile: allFile(filter: {sourceInstanceName: {eq: "activity"}}) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
            fluid {
              aspectRatio
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
// allCloudinaryMedia(sort: {fields: created_at, order: DESC}) {
//   edges {
//       node {
//           secure_url
//           width
//           height
//       }
//   }
// }