import React, { useState, useCallback } from "react";
// nodejs library that concatenates classes
// import clsx from "clsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Img from "gatsby-image"
// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// core components
// import styles from "@assets/jss/components/galleryStyle.js";

// const useStyles = makeStyles(styles);

export default function ImageGallery (props) {
  // const classes = useStyles();
  const { className, images, ...rest } = props;
  // const galleryClasses = clsx({
  //   [classes.gallery]: true,
  //   [className]: className !== undefined
  // });
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const photos = images.map(image => {
    let photo = {
      ...image,
      width: image.aspectRatio,
      height: 1,
    }
    return photo
  })
  const imageRender = ({index, photo, onClick, key}) => {
    const handleClick = event => {
      onClick(event, { photo, index });
    };
    return <div
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={index}
        style={{ display: 'block', margin: '2px' }}
      ><Img
        key={key}
        fixed={photo}
        style={{ display: 'block', margin: '0px' }}
      />
      </div>
  }
  return (
    <div {...rest}>
      <Gallery photos={photos} onClick={openLightbox} renderImage={imageRender} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

ImageGallery.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      aspectRatio: PropTypes.number,
      base64: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      sizes: PropTypes.string
    })
  ).isRequired,
};
